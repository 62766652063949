Menu = {
    $topBar : 'nav.top-bar',
    $mainMenu: '#main-menu',
    $langMenu: '.lang-switcher .lang-menu',
    mainMenuTop : 90,
    init: function()
    {
        if(!tablet)
        {
            this.mainMenuTop = $(this.$topBar).position().top;
        }
        var $langs = $(this.$langMenu).html();

        $(this.$mainMenu).append($langs);
    },
    fixed: function(scroll)
    {

        if(scroll>this.mainMenuTop)
        {
            $(this.$topBar).addClass('fixedMenu');
            $(this.$topBar).closest('.row').addClass('fixedMenu');
        }
        else
        {
            $(this.$topBar).removeClass('fixedMenu');
            $(this.$topBar).closest('.row').removeClass('fixedMenu');
        }
    },
    open: function($handle)
    {
        $closest = $handle.attr('data-closest');
        $handle.toggleClass('active').closest($closest).children('ul').slideToggle();
    }
};