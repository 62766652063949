var CookieInfo = {
    parent: 'body',
    type: 1,
    infoContent: $("<div class='cookieInfo'><div class='row'>"+_t.CookieInfo.text+"</div><div class='row'><span class='cookieBtn close btn'>"+_t.CookieInfo.button_agree+"</span><a href='"+_t.CookieInfo.infoUrl+"' class='cookieBtn btn'>"+_t.CookieInfo.button_more+"</a></div></div>"),

    Init: function () {
        var cookie = this.GetCookie();

        if (cookie == undefined) {
            this.addContent();
            this.onClick();
        }


    },

    addContent: function () {

        $(this.parent).append(this.infoContent);
    },

    onClick: function () {
        $(this.infoContent).find('.cookieBtn.close').on('click', function () {
           CookieInfo.SetCookie();
           CookieInfo.infoContent.remove();
        });
    },

    SetCookie: function () {
        $.cookie('cookieInfo', this.type, {path: '/', expires: 365});
    },

    GetCookie: function () {
        return $.cookie('cookieInfo');
    },
}
