$(document).ready(function () {

    $(document).foundation();

    FoundationModal.Init();

    Fontsize.Init();

    Contrast.Init();

    WCAG.Fix();

    Surveys.Init();

    checkboxStyled();

    anotherOption();

    formValidation();

    Menu.init();

    Login.Init();

    WCAG_menu.init();

    CookieInfo.Init();

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var screen = scroll + $(window).height();

        Menu.fixed(scroll);
    });



    //=============================================//
    //--------------- LIGHTBOX INIT ---------------//
    //=============================================//
    var selectorF = "a[rel*=lightbox]";
    var instanceF = $(selectorF).imagelightbox(
        {
            onStart: function () { overlayOn(); closeButtonOn(instanceF); arrowsOn(instanceF, selectorF); },
            onEnd: function () { overlayOff(); captionOff(); closeButtonOff(); arrowsOff(); activityIndicatorOff(); },
            onLoadStart: function () { captionOff(); activityIndicatorOn(); },
            onLoadEnd: function () { captionOn(); activityIndicatorOff(); $('.imagelightbox-arrow').css('display', 'block'); }
        });

    //=============================================//


    // Oferta - wysuwany boczny panel

    var disallowOpeningThePanel = false;

    jQuery(".offer-section .item-gallery a, .offer-section .column-title-link, .offer-section .offer-link-download a").click(function () {
        disallowOpeningThePanel = true;
        jQuery('.offer-section>div .content').removeClass('active');
        jQuery('.offer-section>div .offer-contact').removeClass('add_active');
    });

    jQuery('#lightbox .lb-close, #lightboxOverlay, .offer-contact').on('click', function () {
        disallowOpeningThePanel = false;
    });

    // Oferta - wysywany panel, ale tylko po kliknięciu w wysunięty kawałek bocznego panelu

    jQuery(".offer-section>div .offer-contact").click(function () {
        if (!disallowOpeningThePanel) {
            if (jQuery(this).parents('.content').hasClass('active')) {
                jQuery(this).parents('.content').removeClass('active');
                jQuery(this).removeClass('add_active');
            } else {
                jQuery(this).parents('.content').addClass('active');
                jQuery(this).addClass('add_active');
                jQuery(this).removeClass('remove_active');
                if (jQuery('.content').hasClass('active')) {
                    jQuery('.content').removeClass('active');
                    jQuery('.offer-contact').removeClass('add_active');
                    jQuery(this).parents('.content').addClass('active');
                    jQuery(this).addClass('add_active');
                    jQuery(this).removeClass('remove_active');
                }
            }
        }
    });
    if (navigator.userAgent.match(/Trident\/7\./)) {
        $('body').on("mousewheel", function () {
            event.preventDefault();

            var wheelDelta = event.wheelDelta;

            var currentScrollPosition = window.pageYOffset;
            window.scrollTo(0, currentScrollPosition - wheelDelta);
        });

        $('body').keydown(function (e) {
            e.preventDefault(); // prevent the default action (scroll / move caret)
            var currentScrollPosition = window.pageYOffset;

            switch (e.which) {

                case 38: // up
                    window.scrollTo(0, currentScrollPosition - 120);
                    break;

                case 40: // down
                    window.scrollTo(0, currentScrollPosition + 120);
                    break;

                default: return; // exit this handler for other keys
            }
        });
    }
    // Oferta - wysywany panel, ale po kliknięciu w cały blok
    //
    // jQuery(".offer-section>div").click(function () {
    //
    //     if(!disallowOpeningThePanel){
    //
    //         if (jQuery(this).find(".content").hasClass('active')){
    //             jQuery(this).find(".content").removeClass('active');
    //             jQuery(this).find(".offer-contact").removeClass('add_active');
    //         }else{
    //             jQuery(this).find(".content").addClass('active');
    //             jQuery(this).find(".offer-contact").addClass('add_active');
    //             jQuery(this).find(".offer-contact").removeClass('remove_active');
    //             if (jQuery(".content").hasClass( 'active' )) {
    //                 jQuery(".content").removeClass('active');
    //                 jQuery(".offer-contact").removeClass('add_active');
    //                 jQuery(this).find(".content").addClass('active');
    //                 jQuery(this).find(".offer-contact").addClass('add_active');
    //                 jQuery(this).find(".offer-contact").removeClass('remove_active');
    //             }
    //         }
    //     }



    // Oferta - KONIEC



    // Dodawanie klasy do elementów listy w sekcji 'Nasza hisotria'

    jQuery("#our-history ul > li:lt(4)").addClass('visible');


    var ile = jQuery('#our-history ul > li').length;

    if (ile <= 3) {
        jQuery("#our-history .news-shadow").css('display', 'none');
        jQuery(".btnmore button.read-more").css('display', 'none');
        jQuery("#our-history section.section-19").css('margin-bottom', '60px');

    } else {
        jQuery("#our-history .news-shadow").css('display', 'block');
        jQuery(".btnmore button.read-more").css('display', 'block');
        jQuery("#our-history section.section-19").css('margin-bottom', '100px');
    };

    jQuery(".btnmore button.read-more").click(function () {
        for (var i = 1; i <= ile; i++) {
            if (jQuery("#our-history ul > li.news:nth-child(" + i + ")").hasClass('visible')) {
                jQuery("#our-history .news-shadow").css('display', 'none');
                jQuery(".btnmore button.read-more").css('display', 'none');
            } else {
                jQuery("#our-history ul > li.news:nth-child(" + i + ")").addClass('visible');
                jQuery("#our-history .news-shadow").css('display', 'none');
                jQuery(".btnmore button.read-more").css('display', 'none');
            }
        }
    });


    //KONIEC
    $('#home-button-top').on('click', function () {
        $('html, body').animate({
            'scrollTop': $('#about-us').offset().top
        }, 700);
    });


    //CLONE
    var clone = $('#offer .widget-menu-icon').parent();
    $(clone).remove().clone().appendTo('#offer #subpage-main-paralax .paralax-content-columns');


    // Oferta - kotwice      
    $('#main-menu .has-dropdown.active ul li a, .bottom-section .bottom-nav > li:nth-child(1) a,.bottom-section .bottom-nav > li:nth-child(2) a,.bottom-section .bottom-nav > li:nth-child(3) a,.bottom-section .bottom-nav > li:nth-child(4) a').on('click', function (ev) {
        var href = jQuery(this).attr('href');
        var hash = $(this).attr('href').replace(/^.*?(#|$)/, '');


        if (($('#' + hash).length) == 1) {
            if (href.indexOf("#") != -1) {
                ev.preventDefault();
                var hash = $(this).attr('href').replace(/^.*?(#|$)/, '');
                $('body, html').animate({ scrollTop: $('#' + hash).offset().top - 70 }, 500, 'swing', function () {
                    window.location.hash = hash;
                });

            } else {

            }
        } else {

        }

    });

    $('#offer .widget-menu-icon li a').on('click', function (ev) {
        if ($('body').hasClass('offer')) {
            ev.preventDefault();
            var hash = $(this).attr('href').replace(/^.*?(#|$)/, '');

            $('html, body').animate({
                'scrollTop': $('#' + hash).offset().top - 70
            }, 500, 'swing', function () {
                window.location.hash = hash;
            });
        }
    });

    var hash = window.location.hash;
    if (hash.length && $(hash).length) {
        $('html, body').animate({
            'scrollTop': $(hash).offset().top - 70
        }, 500);
    }

    //Koniec

    if (!$('html').hasClass("admin-preview")) {

        jQuery('.column-left').addClass('animate fade-left');
        jQuery('.column-right').addClass('animate fade-right');

        $document = $(document);
        $window = $(window);
        $window.scroll(function () {
            var scrollTop = $window.scrollTop() + $window.height();

            $('.animate').each(function () {
                var element = $(this);

                if (scrollTop - 300 > element.offset().top) {
                    if (element.hasClass('fade-left')) {
                        element.addClass('fadeInLeft').addClass('animated');
                    } else if (element.hasClass('fade-right')) {
                        element.addClass('fadeInRight').addClass('animated');
                    } else if (element.hasClass('fade-up')) {
                        element.addClass('fadeInUp').addClass('animated');
                    }
                }
            });
        });
    } else {
        $('.animate').removeClass('animate');
    }


    //      RELACJE INWESTORSKIE
    $('#investors-contact ul li a').on('click', function (ev) {

        var title = jQuery(this).attr('title');

        if (title == "Formularz kontaktowy dla inwestorów") {
            ev.preventDefault();
            alert('JEST!');
        }
    });
    //      KONIEC - RELACJE INWESTORSKIE



    // DLA DOSTAWCÓW

    $('.home .reveal ul li:first-child, .home .reveal .custom-tabs .tabs-content:first-of-type').addClass('is-active');

    // KONIEC - DLA DOSTAWCÓW


    //Clone diva klasy do menu w karierze
    var btnaplikuj = $('#career .career-section .btnaplikuj');
    $(btnaplikuj).remove().clone().appendTo('#career .career-section .side-nav li.has-dropdown ul li a');
    //Koniec - Dodawanie klasy do menu w karierze

    //aktywacja modalu w ladzie korporacyjnym jesli jest ustawiony hash

    openModalIfHashExists();

    $(window).on("hashchange", function (event) {

        openModalIfHashExists();
    });
});

function openModalIfHashExists() {

    var hash = window.location.hash;

    if (hash !== '') {

        hash = hash.replace("#", "");
        $('a[data-hash-trigger="' + hash + '"]').click();
    }
}