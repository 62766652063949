var WCAG = {
    Fix: function () {
        $('meta[class*=\'foundation\']').attr('name', 'foundation_meta').attr('content', '');
        $('meta[charset]').prependTo('head');

        $('#lightbox .lb-container img').attr('alt', '');

        $('a[target="_blank"]').append(_t.targetBlankText);
        $('a[href^="http"]').not('a[href*="' + Constants.baseHref + '"]').append(_t.outLinkText);

        $('a[class="lb-prev"]').append(_t.lightboxPrevImage);
        $('a[class="lb-next"]').append(_t.lightboxNextImage);
    }
};