/**
* Tablica markerow
*/
var markersList = {
    "poland": [
        {"lat": 49.555488, "lng": 22.214620},
        {"lat": 52.437244, "lng": 17.037497},
        {"lat": 52.2478518, "lng": 21.0822185}
        //{"lat": 49.544922, "lng": 21.844588}
    ],
    "france": [
        {"lat": 49.458837, "lng": 5.873333}
    ],
    "ukraine": [
        {"lat": 50.619900, "lng": 26.251617}
    ],
    "belarus": [
        {"lat": 53.880091, "lng": 27.529149}
    ],
    "germany": [
        {"lat": 51.332557, "lng": 6.347395},
        {"lat": 51.40806, "lng": 6.86952}
    ],
    "russia": [
        {"lat": 55.798727, "lng": 37.740736},
    ],
    "russia2": [
        {"lat": 58.603646, "lng": 49.651319}
    ],
    "china": [
        {"lat": 36.283514, "lng": 120.015107}
    ],
    "mexico": [
        {"lat": 21.8331829, "lng": -100.7203835}
    ],
    "finland": [
        {"lat": 47.3722224, "lng": 20.1528064},
        {"lat": 61.3513034, "lng": 22.8812607},
    ]
};


var map, marker, myLatlng, polandCenter, icon;

/**
* Initializacja mapy
*/
function initMap(country) {

    var country = typeof country !== 'undefined' ? country : 'poland';

    $('.widget-map-address .contact-info').css({'visibility':'hidden','opacity':'0','height':'0'});
    $('.widget-map-address .address').css({'visibility':'hidden','opacity':'0','height':'0'});

    if(country === "poland"){

        $('.widget-map-address[data-country-name="poland"] .contact-info').css({'visibility':'visible','opacity':'1','height':'auto'});
    }

    var mapDiv = document.getElementById('map');

    polandCenter = new google.maps.LatLng(52.546345, 19.706536);
    map = new google.maps.Map(mapDiv, {
        center: polandCenter,
        zoom: 6,
        scrollwheel: false,
        rotateControl: false,
        streetViewControl: false,
        mapTypeControl: false
    });

    icon = {
        url: "/themes/stomil/images/marker.png",
        anchor: new google.maps.Point(25,50),
    };

    markersList[country].forEach(function (marker, index) {

        myLatlng = new google.maps.LatLng(marker.lat, marker.lng);
        map.setCenter(myLatlng);
        if(country === "poland"){
            map.setZoom(6);
        }else if(country === "mexico" || country === "finland"){
            map.setZoom(9);
        }
        else{
            map.setZoom(16);
        }
        marker1 = new google.maps.Marker({
            position: myLatlng,
            map: map,
            icon: icon
        });
    });

    map.set('styles', [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]);
}


$(document).ready(function () {

    /**
    * Akcja przy kliknieciu w pozycje na mapie menu
    */
    $('.map-nav-item').on('click', function(){

        $('.map-nav-item').removeClass('active-marker');
        $(this).addClass('active-marker');
        var countryName = $(this).attr('data-country-name');

        initMap(countryName);
        $('.widget-map-address .contact-info').css({'visibility':'hidden','opacity':'0','height':'0'}).addClass('bordered');
        $('.widget-map-address .address').css({'visibility':'hidden','opacity':'0','height':'0'});
        $('.widget-map-address[data-country-name="'+countryName+'"] .contact-info').css({'visibility':'visible','opacity':'1','height':'auto'}).addClass('bordered');
        $('.widget-map-address[data-country-name="'+countryName+'"] .address').css({'visibility':'visible','opacity':'1','height':'auto'});
    });

    /**
     * Animacja na markerach
     */
    $('.map-nav-item svg').fadeIn(2000);
});
