var setStorage = function (key, content, expires)
{
    if (!expires) expires = 24 * 3600;

    var date = new Date();
    var schedule = Math.round((date.setSeconds(date.getSeconds() + expires)) / 1000);

    localStorage.setItem(key, content);
    localStorage.setItem(key + '.expires', schedule);
};

var getStorage = function (key)
{
    var date = new Date();
    var current = Math.round(date / 1000);
    var expires = localStorage.getItem(key + '.expires');

    if (!expires) expires = 0;

    if (expires < current)
    {
        localStorage.removeItem(key);
        localStorage.removeItem(key + '.expires');
    }

    return localStorage.getItem(key);
};

var checkboxStyled = function()
{
    $('input[type=checkbox], input[type=radio]').each(function(){
        $(this).after('<span></span>');
    });
};

var anotherOption = function()
{
    if($('.form-radios-alt').length)
    {
        $('.form-radios input[type=radio]').each(function(){
            if($(this).val() === '-1' && $(this).is(":checked"))
            {
                $(this).closest('.form-radios').next('.form-radios-alt').addClass('open');
            }	
        });
    }
    $('.form-radios input[type=radio]').change(function(){ 
        var $radios_alt = $(this).closest('.form-radios').next('.form-radios-alt');
        if($(this).val() === '-1')
        {
            $radios_alt.addClass('open');
        }
        else
        {
            $radios_alt.removeClass('open');
        }
    }); 
};

var formValidation = function()
{
    if ($.validator)
    {
        $('form').each(function ()
        {
            $(this).validate({
                errorElement: 'small',
                errorClass: 'error',
                submitHandler: function(form)
                {
                    if ($(form).data('ajax-submit') != true)
                    {
                        form.submit();
}
                }
            });
        });
    }
};

// zwraca szerokość scrollbara w danej przeglądarce
function getScrollBarWidth() {
    var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
        widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
    $outer.remove();
    return 100 - widthWithScroll;
};

var getScreenWidth = function(){
    return $(window).width() + getScrollBarWidth();
};

var mobile = (getScreenWidth()<=767) ? 1:0;
var	tablet = (getScreenWidth()<=1024) ? 1:0;

var Loader = {
    loader_obj : '',
    Init: function()
    {
        this.loader_obj = $('<div class="edito-overlay">');		
    },
    Add: function(overlay,handler)
    {
        this.Init();
        overlay = (typeof overlay === 'undefined')? 1 : overlay;
        handler = (typeof handler === 'undefined')? 0 : handler;

        if(overlay == 1)
        {
            this.loader_obj.append('<div class="edito-load"><div class="lt"></div><div class="rt"></div><div class="lb"></div><div class="rb"></div></div>');
        }
        else
        {
            this.loader_obj.addClass(overlay);
            this.loader_obj.append('<div class="edito-load"><div class="lt"></div><div class="rt"></div><div class="lb"></div><div class="rb"></div></div>');
        }

        if(handler)
        {
            handler.append(this.loader_obj);
        }
        else
        {
            $('body').prepend(this.loader_obj);
        }
    },
    Destroy: function()
    {
        $('.edito-overlay').remove();
    }
};
function treeify(list, idAttr, parentAttr, childrenAttr)
{
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent';
    if (!childrenAttr) childrenAttr = 'children';

    var treeList = [];
    var lookup = {};
    
    list.forEach(function (obj)
    {
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });
    
    list.forEach(function (obj)
    {
        if (obj[parentAttr] != null) {
            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });
    
    return treeList;
};