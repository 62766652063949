$(document).ready(function () {

    var filesCount = 0;

    if($('.career-section .form-content').length){

        var text = $('.career-section .form-content .form-file p').text();
        $('.career-section .form-content .form-file label').text(text);
    }

    $('.career-section .form-content .form-file input[type="file"]').on('change', function(e){
        $('.career-section .form-content .form-file label').html('');
        for (var i = 0; i < $(this).get(0).files.length; ++i) {
            $('.career-section .form-content .form-file label').append( '<p>' + $(this).get(0).files[i].name + '</p>' );
        }
    });

    //$('.career-section .form-content .form-file label').on('dragover', function(e) {
    //    e.preventDefault();
    //    e.stopPropagation();
    //    $('.career-section .form-content .form-file label').addClass('drag-over');
    //});
	//
    //$('.career-section .form-content .form-file label').on('dragenter', function(e) {
    //    e.preventDefault();
    //    e.stopPropagation();
    //    $('.career-section .form-content .form-file label').addClass('drag-over');
    //});
	//
    //$('.career-section .form-content .form-file label').on('dragleave', function(){
    //    $('.career-section .form-content .form-file label').removeClass('drag-over');
    //});
	//
    //$('.career-section .form-content .form-file label').on('drop', function(e){
    //    $('.career-section .form-content .form-file label').removeClass('drag-over');
    //    if(e.originalEvent.dataTransfer){
    //        if(e.originalEvent.dataTransfer.files.length) {
    //            e.preventDefault();
    //            e.stopPropagation();
    //            filesCount = e.originalEvent.dataTransfer.files;
    //            $('.career-section .form-content .form-file label').html('');
    //            $.each( filesCount, function(i, fileItem) {
    //                $('.career-section .form-content .form-file label').append( '<p>' + fileItem.name + '</p>' );
    //            });
    //        }
    //    }
    //});
});
