var Contrast = {
    parent: 'body',
    className: 'contrast-',
    elements: '.contrast',
    type: '',

    Init: function () {
        var cookie = this.GetCookie();

        if (cookie != undefined) {
            this.type = cookie;
            this.Change();
        }

        this.onClick();
    },

    Change: function () {
        if (this.type == undefined) {
            return;
        }

        if (this.type == 'default') {
            this.type = '';
        }

        Contrast.ToggleClass();
        Contrast.SetCookie();

    },

    onClick: function () {
        $(this.elements).on('click', function () {
            Contrast.GetType(this);
            Contrast.Change();
        });
    },

    GetType: function (element) {
        this.type = $(element).data('type');
    },

    ToggleClass: function () {
        if (this.type != '') {
            var name = this.className + this.type;
            $(this.parent).toggleClass(name);
            if(!$(this.parent).hasClass(name))
            {
                this.type='';
            }
        }
    },

    SetCookie: function () {
        $.cookie('contrast', this.type, {path: '/'});
    },

    GetCookie: function () {
        return $.cookie('contrast');
    },
};