var Login = {
    Init: function ()
    {
        if ($('.login-modal').length)
        {
            this.Posting();
        }
    },

    Posting: function ()
    {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $('.login-modal form').on('submit', function (e)
        {
            e.preventDefault();
            $('.login-messages').remove();
            var username = $(".login-modal form input[name='username']").val();
            var password = $(".login-modal form input[name='password']").val();
            var remember = $(".login-modal form input[name='remember']").val();

            $.post($(this).data('url'), {username: username, password: password, remember: remember}, function (res)
            {
                var logged = (res.logged)?'success':'error';
                if(res.logged){
                    Loader.Add('disable',$('.login-modal form .message'));
                }
                $.each(res.messages, function (index, message)
                {
                    $('.login-modal form .message').prepend('<span class="login-messages '+ logged +'">' + message + '</span>');
                });
                if (res.logged)
                {
                    location.reload();
                }
            });
        });
    }
};