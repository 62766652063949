
$(document).ready(function(){

    /**
     * Akcja klikniecia na menu tabow
     */
    $(document).on('click', '.custom-tabs .tabs-nav .tabs-nav-item a', function(){

        var $this = $(this);
        var $closestCustomTabs = $this.closest('.custom-tabs');
        var activeIndex = $this.attr('data-tab-index');

        $closestCustomTabs.find('.tabs-nav .tabs-nav-item').removeClass('is-active');
        $closestCustomTabs.find('.tabs-nav .tabs-nav-item a[data-tab-index="'+activeIndex+'"]').closest('.tabs-nav-item').addClass('is-active');

        $closestCustomTabs.find('.tabs-content').removeClass('is-active');
        $closestCustomTabs.find('.tabs-content[data-tab-index="'+activeIndex+'"]').addClass('is-active');
    });

    /**
     * Akcja klikniecia na przycisk next
     */
    $(document).on('click', '.custom-tabs .tabs-nav-next', function(){

        var $this = $(this);
        prevNextNavigation($this, true);
    });

    /**
     * Akcja klikniecia na przycisk prev
     */
    $(document).on('click', '.custom-tabs .tabs-nav-prev', function(){

        var $this = $(this);
        prevNextNavigation($this, false);
    });

    /**
     * Zmienia pozycje na nastepny lub poprzedni tab w zaleznosci od parametru front
     */
    function prevNextNavigation($this, front){

        var $closestCustomTabs = $this.closest('.custom-tabs');
        var activeIndex = $closestCustomTabs.find('.tabs-nav-item.is-active a').attr('data-tab-index');
        var lastIndex = $closestCustomTabs.find('.tabs-nav-item:last-child a').attr('data-tab-index');

        if(front){

            if(activeIndex == lastIndex){
                activeIndex = 0;
            }else{
                activeIndex++;
            }
        }else{

            if(activeIndex == 0){
                activeIndex = lastIndex;
            }else{
                activeIndex--;
            }
        }

        $closestCustomTabs.find('.tabs-nav .tabs-nav-item').removeClass('is-active');
        $closestCustomTabs.find('.tabs-nav .tabs-nav-item a[data-tab-index="'+activeIndex+'"]').closest('.tabs-nav-item').addClass('is-active');

        $closestCustomTabs.find('.tabs-content').removeClass('is-active');
        $closestCustomTabs.find('.tabs-content[data-tab-index="'+activeIndex+'"]').addClass('is-active');
    }
});
